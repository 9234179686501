import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AnimatePresence } from "framer-motion";
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import {Detail} from './pages/ProjectDetail';
import Projects from './pages/Projects';

const App = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Home}/>
        <Route exact path="/listing" component={Projects}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/project-:id" component={Detail}/>
        <Route exact path="/contact" component={Contact}/>
        <Redirect to="/"/>
      </Switch>
    </AnimatePresence>
  );
};

export default App;