import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import { projectsData } from '../data/projects';
// import Button from './Button';
import { motion } from 'framer-motion';

const Project = (props) => {
    const [currentProject] = useState(projectsData);
    const project = currentProject[props.id];
    const variants = {
        initial: {
          opacity : 0,
          x: -100,
          transition : {duration: 0.5},
        },
        animate: {
          opacity : 1,
          x: 0,
          transition : {duration: 0.5}
        },
        exit: {
          opacity : 0,
          transition : {duration: 0.2},
          x: -100,
        }
      }
  return (
    <section className="project-detail">
        <div className="project-detail-banner">
            <Parallax blur={5} bgImage={project.img} bgImageAlt={ project.title } strength={500}>
            </Parallax>
            <div className="project-detail-banner-text">
                <h1>{ project.title }</h1>
                <p>{ project.date }</p>
            </div>
        </div>
        <motion.div className="row project-detail-infos"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
        >
            <div className="project-detail-infos-left">
                <h2>What is it ?</h2>
                <p>{ project.what }</p>
            </div>
            <div className="project-detail-infos-right">
                <h2>The outcome</h2>
                <p>{ project.outcome }</p>
            </div>
        </motion.div>

        <motion.div className="row project-detail-little"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={variants}
        >
            <div className="project-detail-little-img">
                <img src={project.img2} alt={ project.title }/>
            </div>
            <div className="project-detail-little-txt">
                <div>
                    <div>
                        <h3>Project's type</h3>
                        <p>{ project.type }</p>
                    </div>
                    <div>
                        <h3>Technology used</h3>
                        <p>{ project.tech }</p>
                    </div>
                    <div>
                        <h3>Taken time</h3>
                        <p>{ project.time }</p>
                    </div>
                </div>
            </div>
        </motion.div>
        {/* <div className="row">
            <Button link={project.link} text="See the project"></Button>
        </div> */}
    </section>
  );
};

export default Project;