export const projectsData = [
    {
      id: 1,
      title: "Marvelo",
      date: "Janvier 2020",
      type: "web",
      tech : "React, Axios, Visual Studio Code",
      time: "1month to understand react, and 1 month for the development",
      infos:
        "I love Marvel, and I love to learn new stuff about web-development... So why not use the Marvel API?",
      what : 'This is a school project that I did when I was in Haguenau. The goal of this project was to discover the framework React.js, and to make a project using it and linking it to an API. As for the API, I choose the Marvel API, since I like Marvel movies and I thought it would be cool.',
      outcome : 'There are still some problems that I need to solve on this project (try to add a slider, remove the possibility to add a character since it doesn\'t really work,...). But other than that, I\'m glad I got to learn React.js. Indeed, if I want to become a good front-end developer, since this framework is really used, it\'s a good thing I know the basis of it.',
      img: "./assets/images/Mockup-marvelo.jpg",
      img2: "./assets/images/mockup-marvelo-2.jpg",
      link: "",
    },
    {
      id: 2,
      title: "Montréal This Week",
      date: "October 2018",
      type: "Print",
      tech : "Indesign",
      time: "2 month",
      infos:
        "A lot of things can happen in only 1 week in Montreal. And that's how was created Montreal this week, to help yu learn about the weekly things to go or find!",
      what : 'A lot of things can happen in only 1 week in Montreal. And Montreal is a big place for strangers student to come and study, so there are a lot of young people. And these people probably loves music, so this magazine is made for them. Indeed, Montreal this week is a magazine that will help strangers when they come to Montreal. Events, Concerts, Festival… learn everything thanks to Montreal this week! For this class project, we had to make a 4 pages booklet that help people when they come to Montréal. I decide to speak about music, because this is something I really like.',
      outcome : 'This was the first "big" project that I made using indesign. I learned the basics of print design, even the printing techniques. Since I had to taught myself a lot of things to reate this, I\'m proud to say, that it helped me a lot to understand better design in a global way.',
      img: "./assets/images/mockup-montreal.png",
      img2: "./assets/images/montreal-small.jpg",
      link: "",
    },
    {
      id: 3,
      title: "SFS",
      date: "Mars 2020",
      type: "Web development",
      tech : "Webpack, Laravel Mix, Scss, HTML, PHP, JS",
      time: "4 month",
      infos:
        "A very complete school project and my first school experience with complete front-end development, but also kinda hard",
      what : 'This is a group school project that I did when I was in Haguenau. This website is made to help you create some elements for your website such as tables, menus and form. In this project, I was in charge of the integration of all the pages, the front-end aspect of the website, and I also make the video of presentation.',
      outcome : 'This project was hard to finish (and there are still some part that seems not finished). It was complicated by things such as gestion of time, motivation lacking for a people in the project, and overall the project was hard in itself. But in the end, I quite like this project, and it made me learn of some errors that I made on the website.',
      img: "./assets/images/Mockup-SFS.jpg",
      img2: "./assets/images/mockup-sfs-1.jpg",
      link: "",
    },
    {
      id: 4,
      title: "Bibi Graet'z Wine",
      date: "November 2019",
      type: "Print design",
      tech : "Indesign",
      time: "2 months",
      infos:
        "Bibi Graetz needs help to sell his wine, this booklet is here to help him !",
      what : 'This booklet is an individual project made in Vanier. I made the whole layout, but the text and the pictures were given to us by the teachers. That was the biggest challenge in this project : we had to choose the image to put (or to not), and we had to make the text match the picture. I really enjoy doing this project.',
      outcome : 'This print project was very interesting to me, because it is probably the longest booklet I\'ve made. We were in professional condition, so it\'s close to how it would have been done in an agency.',
      img: "./assets/images/wine.jpg",
      img2: "./assets/images/mockup-wine.png",
      link: "",
    },
  ];