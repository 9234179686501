import React, { useState } from 'react';
import Footer from '../components/Footer';
import Me from '../components/Me';
import Nav from '../components/Navigation';
import Skill from '../components/Skills';
import SimpleSlider from '../components/Slider';
import { experienceData } from '../data/experience';
import { schoolData } from '../data/school';
import { motion } from 'framer-motion';
import { BubleAbout } from '../components/Buble'

const About = () => {
    const design = [
      {
        title:"Photoshop",
        content : "Optimize an image, make a funny montage or make some graphics banner."
      },
      {
        title:"Illustrator",
        content : "I love making illustrations !"
      },
      {
        title:"Adobe XD",
        content : "I got to used it a lot recently, and I am loving making mockups with this tool !"
      },
    ]
    const dev = [
      {
        title:"HTML/CSS",
        content : "the basics for a good integration !"
      },
      {
        title:"SCSS",
        content : "the concept of mixin and variable really helps a lot"
      },
      {
        title:"JS/Jquery",
        content : "I can make basic DOM manipûlation, and some ajax"
      },
      {
        title:"React.js",
        content : "I am still learning to get the basics"
      }
    ]
    const [myExperience] = useState(experienceData);
    const experience = myExperience;
    const [myScholarship] = useState(schoolData);
    const schools = myScholarship;
    const variants = {
      initial: {
        opacity : 0,
        x: -100,
        transition : {duration: 0.5},
      },
      animate: {
        opacity : 1,
        x: 0,
        transition : {duration: 0.5, delay: 0.2}
      },
      animate2: {
        opacity : 1,
        x: 0,
        transition : {duration: 0.5, delay: 0.4}
      },
      animate3: {
        opacity : 1,
        x: 0,
        transition : {duration: 0.5, delay: 0.6}
      },
      animate4: {
        opacity : 1,
        x: 0,
        transition : {duration: 0.5, delay: 0.8}
      },
      exit: {
        opacity : 0,
        transition : {duration: 0.2},
        x: -100,
      }
    }
  return (
      <main> 
      <Nav></Nav>
        <section className="row">
            <motion.h1 className="text-center"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={variants}
            >About <span>Me</span></motion.h1>
            <motion.div
              initial="initial"
              animate="animate2"
              exit="exit"
              variants={variants}
            >
              <Me/>
            </motion.div>
            <motion.h2
              initial="initial"
              animate="animate3"
              exit="exit"
              variants={variants}
            >Skills</motion.h2>
            <motion.div className="skill-container"
            initial="initial"
            animate="animate3"
            exit="exit"
            variants={variants}
            >
                <Skill skills={design} title="Design"></Skill>
                <Skill skills={dev} title="Web Development"></Skill>
            </motion.div>
            <motion.div className="text-center"
              initial="initial"
              animate="animate4"
              exit="exit"
              variants={variants}>
              <h2>Experience</h2>
              <div className="about-slider">
                <SimpleSlider class="slider-school" title="Scholarship" content={schools}/>
                <SimpleSlider class="slider-experience" title="Experience" content={experience}/>
              </div>
            </motion.div>
            <BubleAbout></BubleAbout>
        </section>
            <Footer/>
    </main>
  );
};

export default About;