export const schoolData = [
    {
      id: 1,
      title: "DUT MMI",
      date: "2017 - 2019",
      infos:
        "This DUT was my first introduction to the digital domain. Web design, Audi-visual, development, redaction... In two years, I really learned a lot.",
    },
    {
      id: 2,
      title: "Micromedia and Hyperpublishing",
      date: "August - December 2018",
      infos:
        "I was in the CEGEP of Vanier for a semester, and did this. It was mainly design and print, and I really enjoyed the global mood of the school in Canada, and I made a lot of progress concerning print design.",
    },
    {
      id: 3,
      title: "Web development - pro license",
      date: "September 2019 - March 2020",
      infos:
        "When I made this licence, I wanted to improve my web-development skills. And there, I learned the majority of what i know used : react, twig, prestashop, scss, ajax,...",
    },
    
    {
        id: 4,
        title: "Expert in Digital Strategy - Master",
        date: "September 2020 - Now",
        infos:
          "This masters made me learn especially communication. Due to covid, it was mostly at home so kinda hard to follow. But i got to learn more ux and some data analysis stuff.",
      }
]