import React from 'react';

const Me = () => {
  return (
    <div className="me">
        <div className="me-img">
            <img src="./assets/images/me.png" alt="myself" />
        </div>
        <div className="me-txt">
            <h2>Hey!</h2>
            <p>Hi everyone, and welcome to my portfolio ! To present myself, my name is Chiara Iannuzzi, and I'm a french student, currently learning about multimedia and web.</p>
            <p>I have some hobbys, such as drawings, that I've been practicing since I was a teen. And recently, I started digital drawings. Even though I'm not the best, I'm practicing to improve !</p>
            <p>I also like playing video games and read stuff like webtoons (I've been so keen on these recently).</p>
        </div>
    </div>
  );
};

export default Me;