import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Navigation';
import Project from './../components/Project';
import { BubleProject } from '../components/Buble'

export const Detail = (props) => {
    let link = parseInt(props.match.params.id);
    let trueLink = link - 1;
    let theLink = trueLink.toString();
  return (
    <main>
        <Nav></Nav>
        <Project id={theLink}/>
        <BubleProject></BubleProject>
        <Footer></Footer>
    </main>
  );
};