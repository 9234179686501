import React from 'react';

function Footer(props) {
    return (
      <footer className="footer">
          <p>Chiara Iannuzzi - All rights reserved</p>
      </footer>
    );
  };
  
  export default Footer;
  