import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const contents = this.props.content;
    return (
      <div  className={this.props.class}>
        <h3>{this.props.title}</h3>
        <Slider {...settings}>
            {contents.map((item) => 
                <div key={item.id}>
                <h4>{item.title}</h4>
                <p>{item.infos}</p>
              </div>
            )}
        </Slider>
      </div>
    );
  }
}