import React from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Navigation';
import ContactForm from '../components/ContactForm';
import SocialLink from '../components/SocialLink';
import { motion } from 'framer-motion';

const Contact = () => {
  const variants = {
    initial: {
      opacity : 0,
      x: -100,
      transition : {duration: 0.5},
    },
    animate: {
      opacity : 1,
      x: 0,
      transition : {duration: 0.5, delay: 0.2}
    },
    animate2: {
      opacity : 1,
      x: 0,
      transition : {duration: 0.5, delay: 0.4}
    },
    animate3: {
      opacity : 1,
      x: 0,
      transition : {duration: 0.5, delay: 0.6}
    },
    animate4: {
      opacity : 1,
      x: 0,
      transition : {duration: 0.5, delay: 0.8}
    },
    exit: {
      opacity : 0,
      transition : {duration: 0.2},
      x: -100,
    }
  }
  return (
    <main>
      <Nav></Nav>
      <section className="row">
      <div class="contact-title text-center">
        <motion.h1
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}>Contact <span>Me</span></motion.h1>
        <motion.p className="medium-gray-color"
              initial="initial"
              animate="animate2"
              exit="exit"
              variants={variants}>If you want to ask me a question, get in touch with or just say hi to me, don't hesitate to just contact me by filling this contact form!</motion.p>
        </div>
        <motion.div
        className="contact-wrapper"
        initial="initial"
        animate="animate3"
        exit="exit"
        variants={variants}>
          <ContactForm></ContactForm>
          <div className="contact-slider">
            <img src="./assets/images/contact.jpg" alt="travail"/>
          </div>
        </motion.div>
        <motion.div className="social-item-container"
        initial="initial"
        animate="animate4"
        exit="exit"
        variants={variants}>
          <SocialLink nameSocial="LinkedIn" iconClass="fab fa-linkedin-in" link="#"></SocialLink>
          <SocialLink nameSocial="Facebook" iconClass="fab fa-facebook-f" link="#"></SocialLink>
          <SocialLink nameSocial="Twitter" iconClass="fab fa-twitter" link="#"></SocialLink>
        </motion.div>
      </section>
      <Footer/>
  </main>
  );
};

export default Contact;