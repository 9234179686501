import React, { useState } from 'react';
import Footer from '../components/Footer';
import ListingItem from '../components/Listing';
import Nav from '../components/Navigation';
import { projectsData } from '../data/projects';
import { BubleProjects } from '../components/Buble'

const Projects = (props) => {
    const [currentProject] = useState(projectsData);
    const projects = currentProject;
  return (
    <main>
        <Nav/>
        <section className="row">
          <h1 className="text-center"><span>My</span> Projects</h1>
          { projects.map((project) => 
            <ListingItem id={project.id} title={project.title} img={project.img} description={project.infos}/>
          )}
          <BubleProjects></BubleProjects>
        </section>
        <Footer/>
    </main>
  );
};

export default Projects;