import React from 'react';

export const BubleHome = (props) => { 
  return (
    <div className="buble-container">
        <div className="home-circle-1"></div>
        <div className="home-circle-2"></div>
        <div className="home-circle-3"></div>
        <div className="home-circle-4"></div>
    </div>
  );
};

export const BubleNav = (props) => { 
  return (
    <div className="buble-container">
        <div className="nav-circle-1"></div>
        <div className="nav-circle-2"></div>
        <div className="nav-circle-3"></div>
        <div className="nav-circle-4"></div>
    </div>
  );
};

export const BubleAbout = (props) => { 
  return (
    <div className="buble-container">
        <div className="about-circle-1"></div>
        <div className="about-circle-2"></div>
        <div className="about-circle-3"></div>
        <div className="about-circle-4"></div>
    </div>
  );
};

export const BubleProjects = (props) => { 
  return (
    <div className="buble-container">
        <div className="lists-circle-1"></div>
        <div className="lists-circle-2"></div>
        <div className="lists-circle-3"></div>
        <div className="lists-circle-4"></div>
    </div>
  );
};

export const BubleProject = (props) => { 
  return (
    <div className="buble-container">
        <div className="project-circle-1"></div>
        <div className="project-circle-2"></div>
        <div className="project-circle-3"></div>
        <div className="project-circle-4"></div>
    </div>
  );
};