export const experienceData = [
    {
      id: 1,
      title: "Novaway",
      date: "September 2020 - Now",
      infos:
        "Still in Novaway as of today, I learned (and am still) learning a lot. Located in Lyon, I discover in details Symfony and especially Twig, the possibilities that Webpack brings, and many others things !",
    },
    {
      id: 2,
      title: "Atiweb",
      date: "May 2019 - July 2020",
      infos:
        "My first experience as a front web-developer. It started as an internship, and then I got into a sandwich course of one year with them. I got to learn the basics of the integration of web page, and it made me more comfortable with it.",
    }
]