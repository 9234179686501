import React from 'react';
import Button from '../components/Button';
import Nav from '../components/Navigation';
import {BubleHome} from '../components/Buble';
import DynamicText from '../components/DynamicText';
import { motion } from "framer-motion";

const Home = () => {

  const variants = {
    initial: {
      opacity : 0,
      transition : {duration: 0.5},
    },
    animate: {
      opacity : 1,
      x: 0,
      transition : {duration: 0.2, delay: 0.3},
    },
    exit: {
      opacity : 0,
      transition : {duration: 0.2},
      x: -100,
    }
  }

  return (
    <section className="row home">
        <Nav></Nav>
        {/* <BubleHome></BubleHome> */}
        <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}>
          <div>
            <div>
              <h1 className="home-title">Hi ! I'm <span>Chiara Iannuzzi</span></h1>
              <DynamicText/>
            </div>
            <div className="home-slider">
              <video autoPlay loop muted>
                <source src="/assets/images/temple.mp4" type="video/mp4"/>
              </video> 
            </div>
          </div>
          <div className="home-btn">
              <Button text="Learn about me" link="/about"/>
              <Button text="See my projects" link="/listing"/>
              <Button text="Write me something" link="/contact"/>
          </div>
        </motion.div>
        <BubleHome></BubleHome>
    </section>

  );
};

export default Home;