import React, { useState } from 'react';
import { BubleNav } from './Buble';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    const [menuActive, setMenuState] = useState(false);
  return (
    <nav className={`menu ${menuActive ? "is-active" : ""}`}>
      <div class="menu-relative">
          <div className="menu-burger"
            onClick={() => setMenuState(!menuActive)}>
              <span className="line-1">&nbsp;</span>
              <span className="line-2">&nbsp;</span>
              <span className="line-3">&nbsp;</span>
          </div>
          <ul className="menu-nav row">
              <li><NavLink to="/" exact className="hover" activeClassName="is-active">Home</NavLink></li>
              <li><NavLink to="/about" exact className="hover" activeClassName="is-active">About Me</NavLink></li>
              <li><NavLink to="/listing" exact className="hover" activeClassName="is-active">My Projects</NavLink></li>
              <li><NavLink to="/contact" exact className="hover" activeClassName="is-active">Contact Me</NavLink></li>
              <BubleNav></BubleNav>
          </ul>
        </div>
    </nav>
    
  );
};

export default Nav;