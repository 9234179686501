import React from 'react';
import { Link } from 'react-router-dom';

function Button(props) {
  return (
    <Link className="btn" to={props.link}><span><i className="fas fa-arrow-right btn-arrow-left"></i>{props.text}<i className="fas fa-arrow-right btn-arrow-right"></i></span></Link>
  );
};

export default Button;
