import React from 'react';

function Skill(props) {
    const skills = props.skills;
    return (
      <div className="skill">
            <h3 className="skill-title">{props.title}</h3>
            <ul className="skill-infos">
            {skills.map((skill, index) => 
               <li className="skill-infos-item" key={index}><span className="skill-infos-title">{skill.title}</span> - {skill.content}</li>
            )}
            </ul>
      </div>
    );
  };
  
  export default Skill;
  