import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import Fade from 'react-reveal/Fade';

const ListingItem = (props) => {
  return (
        <div className="listing-projects-item">
          <Fade left>
          <div className="listing-projects-img">
            <Link to={ "project-" + props.id }  className="listing-projects-img-link">
              <img src={props.img} alt={props.title} />
            </Link>
            </div>
            <div className="listing-projects-txt">
                <h2>{ props.title }</h2>
                <p>{ props.description }</p>
                <Button link={ "project-" + props.id } text="see more"/>
            </div>
            </Fade>
        </div>
  );
};

export default ListingItem;