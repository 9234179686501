import React from 'react';

const SocialLink = (props) => {
  return (
    <a href={props.link} className="social-item">
       <div className="social-item-icon">
       <i className={props.iconClass}></i>
       </div>
       <div className="social-item-text">
            <p>Join and contact me on my {props.nameSocial} !</p>
       </div>
    </a>
  );
};

export default SocialLink;